import { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import FIND_STREAM_DATA from "../query/base/findMatchStreamById.query";
import FIND_BASE_DETAILS from "../query/base/findBaseDetailsForBaseDashboard.query";
import { AUTH_TOKEN, LOCAL_STORAGE_KEYS } from "../../utils/constant";

export default function useGetRecordingForStream(streamId) {
  const [recordings, setRecordings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  const { data: streamData, loading: isStreamDataLoading } = useQuery(
    FIND_STREAM_DATA,
    {
      variables: { matchStreamId: streamId },
    }
  );

  const getAllRecordings = async (meetingId) => {
    try {
      const res = await fetch(
        `https://api.videosdk.live/v2/recordings/?roomId=${meetingId}`,
        {
          method: "GET",
          headers: {
            authorization: `${AUTH_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();
      return data;
    } catch (err) {
      console.error("Error fetching recordings:", err);
      throw err;
    }
  };

  const fetchRecordings = useCallback(async () => {
    if (!streamId) return;
    setLoading(true);
    try {
      const intervalId = setInterval(async () => {
        if (streamData?.findMatchStreamById?._id && !isStreamDataLoading) {
          try {
            const recordings = await getAllRecordings(
              streamData.findMatchStreamById.channelName
            );
            setRecordings(
              recordings?.data?.map((recording) => ({
                ...streamData?.findMatchStreamById,
                baseDetails: baseData?.findBaseDetails,
                url: recording.file.fileUrl,
                streamStartedAt: recording.createdAt,
              })) || []
            );
          } catch (err) {
            setError(err);
          } finally {
            clearInterval(intervalId);
            setLoading(false);
          }
        }
      }, 200);

      setTimeout(() => {
        clearInterval(intervalId);
        setLoading(false);
      }, 5000);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }, [streamId, streamData, isStreamDataLoading]);

  useEffect(() => {
    fetchRecordings();
  }, [fetchRecordings]);

  return { recordings, loading, error };
}
