import { gql } from "@apollo/client";

export default gql`
  query findAllStreamsByFilter(
    $baseSlug: String
    $baseId: String
    $fetchLiveOnly: Boolean
    $kidPlayerIds: [String]
    $sport: String
    $searchName: String
  ) {
    findAllStreamsByFilter(
      baseSlug: $baseSlug
      baseId: $baseId
      fetchLiveOnly: $fetchLiveOnly
      kidPlayerIds: $kidPlayerIds
      sport: $sport
      searchName: $searchName
    ) {
      _id
      userId
      userDetails {
        _id
        name
        avatar
      }
      appId
      channelName
      gameName
      baseDetails {
        _id
        name
        logo
        city
        slug
        country
      }
      tournamentStatus
      teamIds
      videoThumbnailUrl
      caption
      createdAt
      updatedAt
    }
  }
`;
