import { useCallback, useEffect, useState, useMemo } from "react";
import { AUTH_TOKEN, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { useQuery } from "@apollo/client";
import FIND_ALL_RECORDINGS from "../query/base/findAllStreamsByFilter.query";
import FIND_BASE_DETAILS from "../query/base/findBaseDetailsForBaseDashboard.query";
import FIND_AND_PAGINATE_RECORDINGS from "../query/base/findAndPaginateStreamsByFilter.query";

// TODO: is baseID is provided, get recordings for that base
// TODO: revise deps for effects for more accurate differentiation
export default function useGetRecordings(
  baseId = null,
  kidPlayerIds = [],
  sport = null,
  searchName = null,
  limit = 200
) {
  const [recordingsLoaded, setRecordingsLoaded] = useState(false);
  const [recordings, setRecordings] = useState(null);
  const [error, setError] = useState(null);

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
    },
  });

  const {
    data: videoStreamsData,
    loading: loadingVideoStreams,
    error: errorVideoStreams,
    refetch: refetchVideoStreams,
  } = useQuery(FIND_AND_PAGINATE_RECORDINGS, {
    variables: {
      baseId,
      kidPlayerIds,
      sport,
      searchName,
      limit,
    },
  });

  // Memoize base and highlights to avoid unnecessary re-renders
  const base = useMemo(() => baseData?.findBaseDetails || {}, [baseData]);

  const highlights = useMemo(
    () => videoStreamsData?.findAndPaginateStreamsByFilter?.streams ?? [],
    [videoStreamsData]
  );

  const pageInfo = useMemo(
    () => videoStreamsData?.findAndPaginateStreamsByFilter?.pageInfo ?? {},
    [videoStreamsData]
  );

  // TODO: we get 20 streams but they don't always resolve to an equal amount of highlights
  // TODO: can we get this in the server?
  // TODO can we get the videos in batch in one single query instead of so many queries?
  const getAllRecordings = async (meetingId) => {
    /* console.log("starting fetch"); */

    const res = await fetch(
      `https://api.videosdk.live/v2/recordings/?roomId=${meetingId}`,
      {
        method: "GET",
        headers: {
          authorization: `${AUTH_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    /* console.log("starting fetch"); */
    return data;
  };

  const getRecordingsForAStream = async (stream) => {
    try {
      const recordings = await getAllRecordings(stream.channelName);
      if (recordings?.pageInfo?.total > 0) {
        return recordings.data.map((recording) => ({
          ...stream,
          baseDetails: base,
          url: recording.file.fileUrl,
          streamStartedAt: recording.createdAt,
        }));
      }
      return [];
    } catch (error) {
      console.error(
        `Error fetching recordings for ${stream.channelName}:`,
        error
      );
      setError(error);
      return [];
    }
  };

  const fetchRecordings = useCallback(async () => {
    try {
      const recordingsData = await Promise.all(
        highlights.map(async (stream) => await getRecordingsForAStream(stream))
      );

      const flattenedRecordings = recordingsData
        .flat()
        .sort(
          (a, b) => new Date(b.streamStartedAt) - new Date(a.streamStartedAt)
        );

      /* console.log("flat recordings", recordingsData, ">", flattenedRecordings); */

      setRecordings(flattenedRecordings);
    } catch (error) {
      console.error("Error in fetchRecordings:", error);
      setError(error);
    } finally {
      /* console.log("done"); */

      setRecordingsLoaded(true);
    }
  }, [highlights, base]);

  const refetchStreams = useCallback(
    async (args = {}, shouldGetMore = false) => {
      setRecordings([]);
      setRecordingsLoaded(false);

      try {
        // Refetch both queries
        /* console.log("refetch streams"); */

        await Promise.all([
          baseRefetch(),
          refetchVideoStreams({
            baseId,
            fetchLiveOnly: false,
            limit,
            cursor: shouldGetMore ? pageInfo.endCursor : null,
            ...args,
          }),
        ]);

        /* console.log("promise resolved"); */
      } catch (error) {
        console.error("Error refetching data:", error);
        setError(error);
      }
    },
    [baseId, baseRefetch, limit, pageInfo.endCursor, refetchVideoStreams]
  );

  useEffect(() => {
    /* console.log("effect called"); */

    if (highlights.length > 0 && Object.keys(base).length > 0) {
      /* console.log("fetch all recordings"); */
      fetchRecordings();
    } else if (!baseLoading && !loadingVideoStreams) {
      setRecordings([]);
      setRecordingsLoaded(true);
    }
  }, [highlights, base, fetchRecordings]);

  /* console.log("recordings", recordings); */

  return {
    data: recordings ?? [],
    loading: !recordingsLoaded || baseLoading || loadingVideoStreams,
    error: errorVideoStreams || baseError || error,
    hasNextPage: pageInfo.hasNextPage,
    refetch: refetchStreams,
  };
}
