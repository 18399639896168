const { gql } = require("@apollo/client");

export default gql`
  query FindAndPaginateStreamsByFilter(
    $baseId: String
    $fetchLiveOnly: Boolean
    $kidPlayerIds: [String]
    $sport: String
    $searchName: String
    $limit: Int
    $cursor: String
  ) {
    findAndPaginateStreamsByFilter(
      baseId: $baseId
      fetchLiveOnly: $fetchLiveOnly
      kidPlayerIds: $kidPlayerIds
      sport: $sport
      searchName: $searchName
      limit: $limit
      cursor: $cursor
    ) {
      streams {
        _id
        userId
        userDetails {
          _id
          name
          avatar
        }
        appId
        channelName
        gameName
        baseDetails {
          _id
          name
          logo
          city
          slug
          country
        }
        tournamentStatus
        teamIds
        videoThumbnailUrl
        caption
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
