import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { formatDistance } from "date-fns";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import copy from "copy-to-clipboard";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  Constants,
} from "@videosdk.live/react-sdk";
import Hls from "hls.js";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import FIND_STREAM_DETAILS from "../../gql/query/base/findMatchStreamById.query.js";
import FIND_ALL_LIVE_STREAMS from "../../gql/query/base/findAllStreamsByFilter.query.js";
import {
  AUTH_TOKEN,
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
} from "../../utils/constant";
import useGetRecordings from "../../gql/hooks/useGetRecordings.js";
import { extractIdFromLink } from "../../utils/extractVideoIdFromLink.js";
import VideoCard from "../../component/common/VideoCard.jsx";
import { Spinner } from "flowbite-react";
import useGetRecordingForStream from "../../gql/hooks/useGetRecordingsForStream.js";

const StreamPlay = () => {
  const [meetingId, setMeetingId] = useState(null);
  const [key, setKey] = useState(0);

  const { streamId } = useParams();
  const location = useLocation();
  const { data: allRecordings } = useGetRecordings();
  const { recordings } = useGetRecordingForStream(streamId);
  const autoIsFullScreen = new URLSearchParams(location.search).get(
    "isFullScreen"
  );

  const {
    data: findStreamDetails,
    loading: findStreamDetailsLoading,
    error: findStreamDetailsError,
  } = useQuery(FIND_STREAM_DETAILS, {
    variables: {
      matchStreamId: streamId,
    },
    pollInterval: 1200,
  });

  const channelName = findStreamDetails?.findMatchStreamById?.channelName;

  const {
    data: findAllStreams,
    loading: findAllStreamsLoading,
    error: findAllStreamsError,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      baseId: LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
      fetchLiveOnly: true,
    },
    pollInterval: 5000,
  });

  useEffect(() => {
    if (channelName) {
      setMeetingId(channelName);
      setKey((prevKey) => prevKey + 1);
    }
  }, [channelName, streamId]);

  if (findStreamDetailsLoading || findAllStreamsLoading) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );
  }

  if (findStreamDetailsError || findAllStreamsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  const allStreamsExceptForCurrentOne =
    findAllStreams?.findAllStreamsByFilter?.filter(
      (stream) => stream?._id !== streamId
    );

  const allRecordingsExceptForCurrentOne = allRecordings?.filter(
    (recording) => recording?._id !== streamId
  );

  console.log('recordings',recordings);

  return (
    AUTH_TOKEN &&
    meetingId && (
      <MeetingProvider
        key={key}
        config={{
          meetingId,
          micEnabled: false,
          webcamEnabled: false,
          name: "Viewer",
          mode: Constants.modes.VIEWER,
        }}
        token={AUTH_TOKEN}
      >
        <MeetingConsumer>
          {() => (
            <CommonBackdrop rootName="Base" pageName="Live stream">
              <div className="flex flex-col lg:flex-row gap-8 ">
                <LiveStreamViewer
                  className="w-full lg:w-4/6"
                  streamDetails={findStreamDetails.findMatchStreamById}
                />
                <div className="w-full lg:w-2/6 overflow-y-auto space-y-20">
                  <MoreStreams allStreams={allStreamsExceptForCurrentOne} />
                  <Recordings
                    header="Past Recordings"
                    shouldDisplay={allRecordings.length > 0}
                    allRecordings={allRecordingsExceptForCurrentOne}
                  />
                </div>
              </div>
            </CommonBackdrop>
          )}
        </MeetingConsumer>
      </MeetingProvider>
    )
  );
};

export default StreamPlay;

function LiveStreamViewer({ streamDetails, className }) {
  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully copied link to share!");
  };

  return (
    <div className={className}>
      <ViewerView isLive={streamDetails?.isLive} />

      <div className="flex justify-between mt-4">
        <div className="flex items-center">
          <img
            src={
              RANDOM_AVATAR_URLS[streamDetails?.userDetails?.avatar] ||
              RANDOM_AVATAR_URLS["user4_asset4"]
            }
            alt="User Avatar"
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <div className="text-xl font-bold text-white">
              {streamDetails.caption}
            </div>
            {streamDetails?.createdAt && (
              <div className="text-sm text-gray-400">
                {formatDistance(
                  new Date(),
                  new Date(Number(streamDetails.updatedAt))
                )}{" "}
                ago
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center">
          <EmailShareButton url={window.location.href} className="mr-2">
            <EmailIcon size={32} round />
          </EmailShareButton>
          <WhatsappShareButton url={window.location.href} className="mr-2">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TelegramShareButton url={window.location.href} className="mr-2">
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <FacebookShareButton url={window.location.href} className="mr-2">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.href} className="mr-2">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <button onClick={() => copyToClipboard(window.location.href)}>
            <FontAwesomeIcon
              icon={faCopy}
              className="text-white bg-gray-700 rounded-full p-2"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function MoreStreams({ allStreams }) {
  const streamsExist = allStreams.length > 0;

  return (
    <>
      {streamsExist && (
        <div>
          <h2 className="text-xl font-bold mb-1">More Streams</h2>
          <div className="p-3 bg-surface-secondary/60 border-surface-secondary border-2 rounded-lg space-y-3">
            {allStreams.map((stream) => (
              <VideoCard
                key={stream?._id}
                video={stream}
                createdTime={stream?.updatedAt}
                baseName={stream?.baseDetails?.name}
                avatarUrls={RANDOM_AVATAR_URLS}
                navigateTo={`/${stream?.baseDetails?.slug}/stream/${stream?._id}?isFullScreen=false`}
                fallbackThumbnail="https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function Recordings({ header, allRecordings, shouldDisplay }) {
  return (
    <>
      {shouldDisplay && (
        <div>
          <h2 className="text-xl font-bold">{header}</h2>
          <div className="space-y-3">
            {allRecordings.map((recording) => (
              <VideoCard
                width="220"
                height="150"
                key={recording?._id}
                video={recording}
                baseName={recording?.baseDetails?.name}
                createdTime={recording?.streamStartedAt}
                avatarUrls={RANDOM_AVATAR_URLS}
                navigateTo={`/video/${extractIdFromLink(
                  recording?.url
                )}?isFullScreen=false`}
                fallbackThumbnail="https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

const ViewerView = ({ isLive }) => {
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(true);
  const { join, hlsState, hlsUrls } = useMeeting();
  const [hasJoined, setHasJoined] = useState(false);
  const [joinAttempts, setJoinAttempts] = useState(0);
  const [playbackError, setPlaybackError] = useState(null);
  const [playAttempts, setPlayAttempts] = useState(0);
  const [isPlaybackOn, setIsPlaybackOn] = useState(false);
  const [playbackUrl, setPlaybackUrl] = useState(null);
  const isPlaybackUrlSet = useRef(false);
  const [streamEndedAt, setStreamEndedAt] = useState(0);
  const playerRef = useRef(null);
  const maxJoinAttempts = 3;
  const maxPlayAttempts = 3;
  const joinRetryDelay = 2000;
  const playRetryDelay = 2000;

  const joinMeeting = useCallback(async () => {
    //console.log("test: join meeting");
    setJoinAttempts((prev) => prev + 1);

    try {
      //console.log(`Attempting to join meeting (Attempt ${joinAttempts + 1})`);
      await join();
      //console.log("Successfully joined the meeting");
      setHasJoined(true);
    } catch (error) {
      console.error(
        `Error joining meeting (Attempt ${joinAttempts + 1}):`,
        error
      );
    }
  }, [join, joinAttempts]);

  // update hls playback url for new stream
  useEffect(() => {
    if (hasJoined && hlsUrls?.playbackHlsUrl && hlsState === "HLS_PLAYABLE") {
      if (playbackUrl === null && !isPlaybackUrlSet.current) {
        setPlaybackUrl(hlsUrls.playbackHlsUrl);
        isPlaybackUrlSet.current = true;
      }
    }
  }, [hasJoined, hlsState, hlsUrls.playbackHlsUrl]);

  const disableLoader = useCallback(() => {
    if (!shouldDisplayLoader) return;
    setShouldDisplayLoader(false);
  }, []);

  useEffect(() => {
    if (!hasJoined && isLive) {
      joinMeeting();
    }
  }, [hasJoined, joinMeeting]);

  const playVideo = useCallback(() => {
    if (playerRef.current) {
      playerRef.current
        .play()
        .then(() => {
          //console.log("Video playback started successfully");
          disableLoader();
          setPlayAttempts(playAttempts + 1);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
          setPlaybackError(
            "Failed to play the video after multiple attempts. Please try refreshing the page."
          );
        });
    }
  }, []);

  useEffect(() => {
    const videoPlayer = playerRef.current;
    if (playbackUrl) {
      if (Hls.isSupported()) {
        const hls = new Hls({
          debug: true,
          enableWorker: true,
          lowLatencyMode: true,
          backBufferLength: 90,
        });

        const timeUpdateHandler = () => {
          if (isLive) {
            storePlaybackTime();
          }
        };

        videoPlayer.addEventListener("timeupdate", timeUpdateHandler);

        // start playback from where the stream ended
        const startPlaybackAt = parseFloat(streamEndedAt) ?? 0;

        if (startPlaybackAt > 0) {
          playerRef.current.currentTime = startPlaybackAt;
        }

        hls.loadSource(playbackUrl);
        hls.attachMedia(videoPlayer);

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          //console.log("HLS: Media attached");
          playVideo();
        });

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          //console.log("HLS: Manifest parsed");
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS.js error:", event, data);
          setTimeout(disableLoader, 2000);
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error("HLS: Fatal network error encountered");
                setPlaybackError(
                  "Network error occurred. Please check your connection."
                );
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error("HLS: Fatal media error encountered");
                setPlaybackError(
                  "Media error occurred. Attempting to recover."
                );
                hls.recoverMediaError();
                break;
              default:
                console.error("HLS: Fatal error encountered");
                setPlaybackError(
                  "An error occurred with the video stream. Attempting to recover."
                );
                hls.destroy();
                break;
            }
          }
        });

        return () => {
          videoPlayer?.removeEventListener("timeupdate", timeUpdateHandler);
          hls.destroy();
        };
      } else if (videoPlayer.canPlayType("application/vnd.apple.mpegurl")) {
        videoPlayer.src = playbackUrl;
        videoPlayer.addEventListener("loadedmetadata", () => {
          playVideo();
        });
      } else {
        console.error("HLS is not supported in this browser.");
        setPlaybackError(
          "Your browser doesn't support the required video format."
        );
      }
    } else {
      /* console.log("HLS is not playable yet or URLs are not available"); */
      if (!isLive) {
        setTimeout(disableLoader, 8000);
      }
    }
    // It doesn't make sense trying to replay video when hls is not playable but playback url is present
    /* if (hlsState !== "HLS_PLAYABLE") {
        console.log("test: play video", hlsState);
        setTimeout(playVideo, 2000);
      } */
  }, [playVideo, disableLoader, isLive, playbackUrl]);

  const FALLBACK_THUMBNAIL =
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png";

  function getHLSStateMessage(hlsState) {
    switch (hlsState) {
      case "HLS_STOPPED":
        return {
          message: "The streamer has ended streaming.",
          subMessage: "You may watch other streams or highlights.",
        };
      case "HLS_STARTING":
        return {
          message: "Stream is starting",
          subMessage:
            "We're getting things ready. This should only take a moment.",
        };
      case "HLS_STARTED":
        return {
          message: "Stream is processing",
          subMessage:
            "The stream is being prepared for viewing. It should be available shortly.",
        };
      case "HLS_PLAYABLE":
        return {
          message: "🟢 Stream is live",
          subMessage: "Enjoy the broadcast!",
        };
      case "HLS_STOPPING":
        return {
          message: "Stream is ending",
          subMessage:
            "The broadcaster is wrapping up. The stream will end soon.",
        };
      default:
        return {
          message: "Stream status unknown",
          subMessage:
            "We're having trouble determining the stream status. Please try refreshing the page.",
        };
    }
  }

  useEffect(() => {
    if (hasJoined && !isLive && !playbackUrl) {
      setTimeout(disableLoader, 8000);
    } else if (hlsState === "HLS_PLAYABLE") {
      setShouldDisplayLoader(true);
    }
  }, [hasJoined, hlsState, isLive]);

  const isPlaybackUrlAvailable =
    typeof playbackUrl === "string" && playbackUrl?.trim()?.length > 0;

  // adjust playback with the point when the stream ended
  const storePlaybackTime = () => {
    if (playerRef.current) {
      setStreamEndedAt(playerRef.current.currentTime.toString());
    }
  };

  useEffect(() => {
    if (!isLive) return;
    if (!playbackUrl) {
      setShouldDisplayLoader(true);
      joinMeeting();
    } else if (hlsState === "HLS_PLAYABLE") {
      disableLoader();
    }
  }, [hlsState, hlsUrls.playbackHlsUrl, isLive, playbackUrl]);

  // reset the states when another stream is started right after one has ended (live status changed), and the page hasn't been refreshed
  useEffect(() => {
    if (
      playbackUrl !== null &&
      isLive &&
      hlsUrls.playbackHlsUrl === null &&
      isPlaybackUrlSet.current
    ) {
      setStreamEndedAt(0); // start at the beginning
      setPlaybackUrl(null);
      setShouldDisplayLoader(true);
      isPlaybackUrlSet.current = false;

      joinMeeting(); // initiate hls
    }
  }, [isLive]);

  return (
    <div className="w-full">
      <div className="relative w-full pb-[56.25%]">
        <div className="absolute inset-0">
          {isPlaybackUrlAvailable ? (
            <video
              autoPlay
              muted
              ref={playerRef}
              id="hlsPlayer"
              controls
              loop={false}
              className="absolute inset-0 w-full h-full object-cover"
              playsInline
              onError={(e) => {
                setIsPlaybackOn(false);
                console.error("Video error:", e.target.error);
                setPlaybackError(
                  `Video error: ${e.target.error?.message || "Unknown error"}`
                );
                setTimeout(() => {
                  setPlayAttempts(0);
                  playVideo();
                }, 2000);
              }}
              onPlay={() => setIsPlaybackOn(true)}
              onEnded={() => setIsPlaybackOn(false)}
            >
              Your browser does not support video playback.
            </video>
          ) : (
            <div className="absolute inset-0">
              <img
                src={FALLBACK_THUMBNAIL}
                alt="Live stream"
                className="w-full h-full object-cover"
              />
              {shouldDisplayLoader && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                  <Spinner className="w-32 h-32" />
                </div>
              )}
            </div>
          )}

          {!shouldDisplayLoader && (
            <>
              {hlsState !== "HLS_PLAYABLE" && !isPlaybackUrlAvailable && (
                <div className="absolute inset-0 flex flex-col items-center bg-black/60 justify-center font-bold text-white">
                  <span>{getHLSStateMessage(hlsState).message}</span>
                  <span>{getHLSStateMessage(hlsState).subMessage}</span>
                </div>
              )}
              {isLive && (
                <div className="absolute right-5 top-3 flex flex-row gap-1 items-center">
                  <span className="w-4 h-4 rounded-full bg-green" />
                  <span className="font-bold text-white">Live</span>
                </div>
              )}
              {!isLive && isPlaybackUrlAvailable && (
                <div className="absolute right-5 top-3 flex flex-row gap-1 items-center">
                  <span className="w-4 h-4 rounded-full bg-red-500" />
                  <span className="font-bold text-white">Stream Ended</span>
                </div>
              )}
              {!isLive &&
                hlsState === "HLS_STOPPED" &&
                isPlaybackUrlAvailable &&
                !isPlaybackOn && (
                  <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center drop-shadow-2xl font-bold text-white">
                    <span>You have reached the end of the stream.</span>
                    <span>You can watch other streams or highlights.</span>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
